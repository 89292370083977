"use client";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useState } from "react";
const CopyrightContent = dynamic(
  () => import("./CopyrightContent/CopyrightContent"),
  {
    ssr: false
  }
);

const Copyright = () => {
  const [isHoverCopyright, setHoverCopyright] = useState<boolean>(false);
  return (
    <Link
      target="_blank"
      href="https://www.linkedin.com/in/nguyen-huu-huan-134741225/"
      className="absolute bottom-6 left-[50%] translate-x-[-50%] w-max"
    >
      <div
        className="rounded-full cursor-pointer bg-indigo-50 drop-shadow-lg"
        onMouseOver={() => setHoverCopyright(true)}
        onMouseOut={() => setHoverCopyright(false)}
      >
        <div className="relative flex items-center justify-center w-full text-xl">
          <span className="relative flex w-3 h-3">
            <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-sky-400"></span>
            <span className="relative inline-flex w-3 h-3 rounded-full bg-sky-400"></span>
          </span>
          {isHoverCopyright && (
            <CopyrightContent isHoverCopyright={isHoverCopyright} />
          )}
          <p
            className={`font-bold cursor-pointer duration-200 rounded-r-full px-3 py-1 ${
              isHoverCopyright ? " text-[white] bg-[blue]" : "text-[blue]"
            } `}
          >
            @Nguyen Huu Huan
          </p>
        </div>
      </div>
    </Link>
  );
};

export default Copyright;
