import(/* webpackMode: "eager" */ "/vercel/path0/client/components/Layout/Copyright/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/components/Layout/CustomImage/CloudinaryImage/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/client/components/Login/LoginForm/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/client/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/client/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/client/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
