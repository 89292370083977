import { z } from "zod";
const loginSchema = z.object({
  username: z
    .string()
    .min(4, { message: "Username is too short, at least 4 characters!" })
    .max(20, { message: "Username is too long, atmost 20 characters!" }),
  password: z
    .string()
    .min(3, { message: "Password is too short, at least 3 characters!" }),
  loginMode: z.enum([
    "customCredentials",
    "facebook",
    "google",
    "instagram",
    "linkedin"
  ])
});
type LoginSchema = z.infer<typeof loginSchema>;
export { loginSchema };
export type { LoginSchema };
