"use client";
import dynamic from "next/dynamic";
import { useSession, getCsrfToken } from "next-auth/react";
import { zodResolver } from "@hookform/resolvers/zod";
import Link from "next/link";
import { redirect } from "next/navigation";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LoginSchema, loginSchema } from "@components/Login/LoginSchema";
import { AiOutlineEye } from "@react-icons/all-files/ai/AiOutlineEye";
import { AiOutlineLogin } from "@react-icons/all-files/ai/AiOutlineLogin";
import { CgFacebook } from "@react-icons/all-files/cg/CgFacebook";
import { FaGooglePlusG } from "@react-icons/all-files/fa/FaGooglePlusG";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import authenticate from "@libs/auth/login";
const LoadingEffect = dynamic(
  () => import("@components/Layout/LoadingEffect"),
  {
    ssr: false
  }
);
const LoginVerifyError = dynamic(
  () => import("@components/Login/LoginVerifyError/LoginVerifyError"),
  {
    ssr: false
  }
);
const LoginFormError = dynamic(
  () => import("@components/Login/LoginForm/LoginFormError"),
  {
    ssr: false
  }
);

const LoginForm = () => {
  const [verifyError, setVerifyError] = useState<boolean>(false);
  const { status } = useSession();
  const [verifyLoading, setVerifyLoading] = useState<boolean>(false);
  const [csrfToken, setCsrfToken] = useState<string>("");
  const [passwordShown, setPasswordShown] = useState(false);

  const verifyUser: SubmitHandler<LoginSchema> = async (data) => {
    const { loginMode, username, password } = data;
    setVerifyLoading(true);
    let res;
    try {
      if (loginMode === "customCredentials") {
        res = await authenticate(loginMode, {
          username,
          password,
          csrfToken: csrfToken,
          redirect: false
        });
      } else {
        res = await authenticate(loginMode, {
          redirect: false
        });
      }
      if (res?.error) throw new Error(res.error);
    } catch (e) {
      console.error(e);
      setVerifyError(true);
      setVerifyLoading(false);
    } finally {
      if (res?.redirect) redirect(res.redirect);
    }
  };
  useEffect(() => {
    const getCsrf = async () => {
      try {
        const csrf = await getCsrfToken();
        if (csrf) setCsrfToken(csrf);
      } catch (e) {
        console.error(e);
      }
    };
    getCsrf();
  }, []);
  const toggleDisplayPassword = () => {
    setPasswordShown(!passwordShown);
  };
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginSchema>({ resolver: zodResolver(loginSchema) });
  return (
    <form
      className="3xl:[60%] 2xl:w-[60%] xl:w-[50%] lg-[40%] w-[95%] mx-auto justify-center items-center sticky"
      method="POST"
      onSubmit={handleSubmit(async (data: LoginSchema) => {
        await verifyUser({ ...data, loginMode: "customCredentials" });
      })}
    >
      {verifyError && <LoginVerifyError verifyError={verifyError} />}
      <h1 className={`font-bold text-5xl mb-4 text-blue-500 text-center`}>
        Login
      </h1>
      <input type="hidden" name="csrfToken" value={csrfToken} />
      <div className="flex flex-col items-center justify-center w-full login-form-wrapper 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row">
        <div className="input lg:min-w-[75%] min-w-full mb-10 flex flex-col items-center duration-200">
          <label
            htmlFor="username"
            className="w-full pl-1 text-xl font-bold text-left"
          >
            Username/Email
          </label>
          <input
            type="text"
            id="username"
            className={`${
              errors.username
                ? "border-b-2 border-b-red-500 focus:border-b-red-500"
                : "focus:border-indigo-400 focus:border-4"
            } w-full dark:bg-white username border  focus:border-2 rounded-xl d-block my-2 pl-3 py-[1rem] duration-150 text-black`}
            placeholder="Username/Email"
            {...register("username")}
          />
          {errors.username && <LoginFormError error={errors.username} />}
          <div className="relative w-full">
            <label
              htmlFor="password"
              className="w-full pl-1 text-xl font-bold text-left"
            >
              Password
            </label>
            <input
              type={passwordShown ? "text" : "password"}
              className={`${
                errors.password
                  ? "border-b-2 border-b-red-500 focus:border-b-red-500"
                  : "focus:border-indigo-400 focus:border-4"
              } w-full dark:bg-white password border my-2  focus:border-2 rounded-xl pl-3 py-[1rem] text-black duration-150`}
              placeholder="Password"
              {...register("password")}
            />

            <div
              className={`slant-indicator absolute right-8 my-4 -translate-y-1/2 top-1/2 w-[1.5px] h-[40px] ${
                passwordShown && "h-0"
              } bg-black rotate-[135deg] duration-150 z-50`}
            ></div>
            <AiOutlineEye
              onClick={toggleDisplayPassword}
              className="absolute p-1 my-4 text-black duration-100 -translate-y-1/2 rounded-full top-1/2 right-3 hover:bg-sky-50"
              size={40}
            />
            {errors.password && <LoginFormError error={errors.password} />}
          </div>
        </div>
        <button
          className={`group submit lg:ml-[5%] ml-0 max-w-[6rem] hover:max-w-[8rem] overflow-x-hidden flex items-center justify-between py-2 px-[1.2rem] bg-[#4776E6] bg-gradient-to-r from-[#4776E6] to-[#8E54E9] drop-shadow-xl rounded-xl duration-150`}
          type="submit"
          {...register("loginMode", { value: "customCredentials" })}
        >
          <p className="non-selectable text-white text-[1.4rem]">Login</p>
          <div className="flex items-center justify-center ml-6 duration-100 group-hover:ml-4 login-logo-container">
            <AiOutlineLogin size={30} className="text-[white]" />
          </div>
        </button>
      </div>
      <div>
        <div className="horizontal-line-wrapper flex items-center justify-center w-full h-[2px] rounded-xl my-10">
          <div className="horizontal-line bg-slate-200 w-[65%] h-[2px] relative">
            <p className="text-black absolute top-[50%] left-[calc(50%-1rem)] text-center mt-[-0.9rem] bg-white px-4 text-xl">
              Or
            </p>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-center w-full gap-2 py-2 mt-10 mb-5 login-alternatives lg:mt-0">
          <button
            type="button"
            aria-label="Login with Facebook"
            onClick={() => {
              verifyUser({
                username: "",
                password: "",
                loginMode: "facebook"
              });
            }}
            className="rounded-lg bg-white drop-shadow-[0px_5px_6px_rgba(159,184,255,0.5)] hover:drop-shadow-2xl"
          >
            <CgFacebook
              className="p-1 hover:p-1.5 text-blue-600 duration-200 rounded-lg hover:bg-blue-600 hover:border-0 hover:text-white"
              size={70}
            />
          </button>
          <button
            aria-label="Login with Google"
            type="button"
            onClick={() => {
              verifyUser({
                username: "",
                password: "",
                loginMode: "google"
              });
            }}
            className="rounded-lg bg-white drop-shadow-[0px_5px_6px_rgba(159,184,255,0.5)] hover:drop-shadow-2xl"
          >
            <FaGooglePlusG
              className="rounded-lg hover:bg-[red] p-1 hover:p-1.5 duration-200 text-[red] hover:border-0 hover:text-white"
              size={70}
            />
          </button>
          <button
            aria-label="Login with LinkedIn"
            type="button"
            onClick={() => {
              verifyUser({
                username: "",
                password: "",
                loginMode: "linkedin"
              });
            }}
            className="rounded-lg bg-[blue] drop-shadow-[0px_5px_6px_rgba(159,184,255,0.5)] hover:drop-shadow-2xl"
          >
            <FaLinkedin
              className="rounded-lg bg-[blue] hover:bg-[white] hover:text-[blue] duration-200 text-[white] hover:border-0"
              size={70}
            />
          </button>
          <button
            type="button"
            aria-label="Login with Instagram"
            onClick={async () => {
              const Swal = (await import("sweetalert2")).default;
              Swal.fire({
                title: "Upcoming Feature!",
                icon: "info",
                html: "This feature is coming soon, please check back later!"
              });
            }}
            className="rounded-lg bg-white drop-shadow-[0px_5px_6px_rgba(159,184,255,0.5)] hover:drop-shadow-2xl"
          >
            <svg width="0" height="0">
              <linearGradient
                id="blue-gradient"
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
              >
                <stop stopColor="#feda75 " offset="0%" />
                <stop stopColor="#fa7e1e " offset="25%" />
                <stop stopColor="#d62976 " offset="50%" />
                <stop stopColor="#962fbf " offset="75%" />
                <stop stopColor="#4f5bd5" offset="100%" />
              </linearGradient>
            </svg>
            <FaInstagram
              className="p-1 hover:p-1.5 duration-200 from-[white] to-[white] rounded-lg hover:fill-white fill-[url(#blue-gradient)] bg-gradient-to-r hover:from-purple-500 hover:to-pink-500 hover:border-0"
              size={70}
            />
          </button>
        </div>
      </div>
      <div className="text-xl font-bold text-center text-black">
        Not a member?{" "}
        <Link href="signup">
          <div className="inline-block py-0.5 text-indigo-800 underline duration-200 underline-offset-4 hover:no-underline hover:bg-indigo-800 hover:text-white hover:px-3 hover:ml-2 hover:rounded-md">
            Signup here
          </div>
        </Link>
      </div>
      {(status === "loading" || verifyLoading) && <LoadingEffect />}
      <p className="text-center text-[red] my-3">Login credential:</p>
      <p className="text-center text-[red] my-2">
        Username: Huan - Password: 111
      </p>
    </form>
  );
};
export default LoginForm;
